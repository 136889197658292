import { mapGetters } from "vuex";
import moment from "moment";
import { postAPICall } from "../helpers/httpHelper";

export default {
  computed: {
    ...mapGetters("formBuilders", [
      "getFormBuilder",
      "getFormBuilderFillingInfo",
    ]),
    ...mapGetters("contacts", ["getUpdateDocumentPrimaryDetailsStatus"]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveContactType"]),
  },
  data() {
    return {
      entityDataObject: {},
      templateDataObject: {},
    };
  },
  methods: {
    getFormbuilderFieldValue(stepKey, row) {
      let [sIndex, tempId, key] = stepKey.split("#");
      if (row?.formbuilder_steps_data?.[sIndex]) {
        let currentStep = row.formbuilder_steps_data[sIndex];
        if (
          currentStep?.entity_data_id &&
          this.entityDataObject?.[currentStep.entity_data_id]?.[tempId]?.[key]
        ) {
          let value =
            this.entityDataObject[currentStep.entity_data_id][tempId][key];
          if (
            this.entityDataObject?.[currentStep.entity_data_id]?.[tempId]?.[
              key + "/name"
            ]
          ) {
            value =
              this.entityDataObject[currentStep.entity_data_id][tempId][
                key + "/name"
              ];
          } else if (
            this.entityDataObject?.[currentStep.entity_data_id]?.[tempId]?.[
              key + "_code"
            ]?.formatInternational
          ) {
            value =
              this.entityDataObject?.[currentStep.entity_data_id]?.[tempId]?.[
                key + "_code"
              ]?.formatInternational;
          }
          return value;
        }
      }
      return "";
    },
    async getFormbuilderUserData(data) {
      let entityDataIds = [],
        templateDataIds = [];
      (data || []).forEach((row) => {
        entityDataIds = [
          ...entityDataIds,
          ...row.formbuilder_steps_data
            .filter((e) => e.type == "ENTITY" && e.entity_data_id)
            .flatMap((s) => s.entity_data_id),
        ];
        templateDataIds = [
          ...templateDataIds,
          ...row.formbuilder_steps_data
            .filter((e) => e.type == "TEMPLATE" && e.template_data_id)
            .flatMap((s) => s.template_data_id),
        ];
      });
      let [entityData, templateData] = await Promise.all([
        this.postCall("/entities-data/selected-data", {
          entity_data_ids: entityDataIds,
        }),
        this.postCall("/templates-data/selected-data", {
          template_data_ids: templateDataIds,
        }),
      ]);
      entityData.forEach((row) => {
        this.$set(this.entityDataObject, row._id, row.entityData);
      });
      templateData.forEach((row) => {
        this.$set(this.templateDataObject, row._id, row.template_data);
      });
    },
    async postCall(url, params) {
      try {
        const response = await postAPICall("POST", url, params);
        return response.data;
      } catch (e) {
        console.log("", e);
        return {};
      }
    },
    getReviewerData(data) {
      let user = this.checkReviewer(data);
      let result = {
        title: "User details",
        user_name: "",
        reason: "",
        status: "",
        date: "",
        label1: "",
      };
      if (user) {
        result.user_name = user.user_name;
        if (user.rejected_status) {
          result.title = "Rejection details:";
          result.reason = user.rejected_reason;
          result.status = "REJECTED";
          result.date = user.rejected_at;
          result.label1 = "Rejected by: " + user.user_name;
        } else if (user.approved_status) {
          result.title = "Approval details:";
          result.date = user.approved_at;
          (result.status = "APPROVED"),
            (result.label1 = "Approved by: " + user.user_name);
        }
      }
      return result;
    },
    checkReviewer(formbuilderData) {
      if (formbuilderData?.approval_users && formbuilderData?.priority_based) {
        return formbuilderData?.approval_users.find(
          (e) => !e.final_approver && (e.rejected_status || e.approved_status)
        );
      }
      return null;
    },
    getApprovalParams(formbuilderData, currentuserIndex) {
      formbuilderData.approval_users[currentuserIndex] = {
        ...formbuilderData.approval_users[currentuserIndex],
        ...{
          approved_status: true,
          approved_at: new Date(),
          approved_by: this.getAuthenticatedUser.email,
          approval_action: true,
          user_name:
            this.getAuthenticatedUser.first_name +
            " " +
            this.getAuthenticatedUser.last_name,
        },
      };
      let isCompleted =
        formbuilderData.priority_based &&
        !formbuilderData.approval_users[currentuserIndex]?.final_approver
          ? false
          : true;
      formbuilderData.approval_users.forEach((e) => {
        if (!e?.approved_status && formbuilderData.approve_all_user) {
          isCompleted = false;
        }
      });
      return {
        id: formbuilderData._id,
        approval_users: formbuilderData.approval_users,
        approval_status: isCompleted ? "APPROVED" : "PENDING",
        current_user_index: currentuserIndex,
        approval_action: true,
        ...(isCompleted &&
          formbuilderData.formbuilder_steps_data && {
            approval_update: true,
            step_data: formbuilderData.formbuilder_steps_data,
          }),
      };
    },
    getRejectParams(formbuilderData, currentuserIndex, reasonForReject = "") {
      formbuilderData.approval_users[currentuserIndex] = {
        ...formbuilderData.approval_users[currentuserIndex],
        ...{
          rejected_status: true,
          approval_status: true,
          rejected_at: new Date(),
          rejected_by: this.getAuthenticatedUser.email,
          user_name:
            this.getAuthenticatedUser.first_name +
            " " +
            this.getAuthenticatedUser.last_name,
          rejected_reason: reasonForReject,
        },
      };
      let isCompleted =
        formbuilderData.priority_based &&
        !formbuilderData.approval_users[currentuserIndex]?.final_approver
          ? false
          : true;
      return {
        id: formbuilderData._id,
        approval_users: formbuilderData.approval_users,
        current_user_index: currentuserIndex,
        ...(isCompleted && {
          approval_action: true,
          approval_status: "REJECTED",
        }),
      };
    },
    getCurrentApprover(formbuilderData) {
      if (formbuilderData?.approval_users) {
        return formbuilderData?.approval_users.findIndex((el) => {
          if (el?.user_type == "COMPANY") {
            if (el?.preferred_company_users && !el?.any_user_can_approve) {
              return (
                el.preferred_company_users.indexOf(
                  this.getAuthenticatedUser._id
                ) > -1
              );
            } else if (el?.company_user_type && el?.any_user_can_approve) {
              return (
                (this.getAuthenticatedUser.roles || []).find(
                  (e) => e.userType_id == el?.company_user_type
                ) ||
                el.preferred_company_users.indexOf(
                  this.getAuthenticatedUser._id
                ) > -1
              );
            }
          } else if (el?.user_type == "APPLICATION") {
            if (el?.relational_user) {
              if (this.getAuthenticatedUser.contact_types) {
                return this.getAuthenticatedUser.contact_types.find(
                  (ct) => ct.contact_type == el.application_user_type
                )
                  ? true
                  : false;
              }
              return this.approver == this.getAuthenticatedUser.email;
            }
            let cType = this.getActiveContactType?.contact_type?._id;
            return (
              el.preferred_application_users.indexOf(
                this.getAuthenticatedUser.email
              ) > -1 ||
              (el?.any_user_can_approve &&
                this.getAuthenticatedUser.is_contact &&
                cType == el.application_user_type)
            );
          } else if (el?.user_type == "LINKEDFIELD") {
            return (
              this.approver == this.getAuthenticatedUser.email ||
              el.approval_linked_field ==
                this.getAuthenticatedUser.approval_linked_field
            );
          }
        });
      }
      return null;
    },
    async updatedContactDetails(
      repeatableData,
      stepContacts,
      allow_new,
      contact_type,
      contact_entity_type,
      parent_id
    ) {
      if (repeatableData.length) {
        let allContacts = [];
        repeatableData.forEach((ele) => {
          if (ele?.email) {
            let selectedContact = stepContacts.find(
              (e) => e.email.toLowerCase() == ele.email.toLowerCase()
            );
            if (selectedContact?._id) {
              allContacts.push({
                ...ele,
                ...{
                  contact_id: selectedContact._id,
                  contact_type: selectedContact.contact_type,
                },
                ...(selectedContact.account_data_id && {
                  entity_data_id: selectedContact.account_data_id,
                }),
              });
            } else if (allow_new) {
              allContacts.push({
                ...ele,
                ...{
                  allow_new: allow_new,
                  contact_type: contact_type,
                  entity_id: contact_type,
                  company_id: this.getFormBuilder.company,
                  created_by: this.getFormBuilder.created_by,
                },
                ...(contact_entity_type == "BUSINESS" && {
                  parent_contact: parent_id,
                }),
              });
            }
          }
        });
        if (allContacts.length) {
          await this.$store.dispatch("contacts/updateDocumentPrimaryDetails", {
            params: allContacts,
          });
          if (this.getUpdateDocumentPrimaryDetailsStatus) {
            this.$notify.success({
              title: "Success",
              message: "Data updated successfully",
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Data updated successfully",
            });
          }
        } else {
          this.$notify.success({
            title: "Success",
            message: "Data updated successfully",
          });
        }
      }
    },
    setFormbuilderFormData(form, currentStepIndex, template_id) {
      let obj = {
        [currentStepIndex]: {
          [template_id]: form,
        },
      };
      let existedData = this.getFormBuilderFillingInfo;
      if (existedData && existedData[currentStepIndex]) {
        if (existedData[currentStepIndex][template_id]) {
          existedData[currentStepIndex][template_id] = {
            ...existedData[currentStepIndex][template_id],
            ...form,
          };
        } else {
          existedData[currentStepIndex] = {
            ...existedData[currentStepIndex],
            ...{ [template_id]: form },
          };
        }
      } else {
        existedData = { ...existedData, ...obj };
      }
      this.$store.commit(
        "formBuilders/setFormBuilderFillingInfo",
        existedData,
        {
          root: true,
        }
      );
    },
    mapDateValues(fields, form) {
      let result = JSON.parse(JSON.stringify(form));
      const currentDate = moment().startOf("day");
      const timeZoneOffsetMinutes = new Date().getTimezoneOffset();
      const adjustedDate = currentDate.add(timeZoneOffsetMinutes, "minutes");
      fields.forEach((el) => {
        if (el?.properties?.autofill_response) {
          result[el.key] = adjustedDate.format();
        }
      });
      return result;
    },
    async updateParentDataByEntityVariableInDocuments(
      fields,
      form,
      includeData
    ) {
      let data = {};
      fields.forEach((element) => {
        if (element && element.type == "CHECKBOX" && element.group_key) {
          data[element.key] =
            this.documentData[element.key] == true || element.value == true
              ? true
              : false;
        } else if (element.content) {
          data[element.key] = element.content;
        } else if (element.value) {
          data[element.key] = element.value;
        } else if (element.source) {
          data[element.key] = element.source;
        } else if (element.files && element.files.length) {
          data[element.key] = element.files;
        } else if (element.type != "CHECKBOX" && !element.group_key) {
          data[element.key] = "";
        }
      });
      form = data;
      let updatedData = [];
      updatedData = [
        ...updatedData,
        ...(await this.getParentUpdatingData1(fields, form)),
      ];
      let allPromises = await updatedData.map(async (dt) => {
        let params = { ...dt };
        if (includeData?.company_id) {
          params = {
            ...params,
            ...{
              company_id: this.currentEntity.company_id,
              user_id: this.currentEntity.created_by,
            },
          };
        }
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          params
        );
        return dt;
      });
      await Promise.all(allPromises);
    },
    //remove duplicate data
    removeDuplicateData(updatedData) {
      let result = [];
      updatedData.forEach((data) => {
        let existedDataIndex = result.findIndex(
          (e) =>
            e.entity_data_id == data.entity_data_id &&
            data.template_id == e.template_id
        );
        if (existedDataIndex == -1) {
          result.push(data);
        } else {
          if (
            result[existedDataIndex] &&
            result[existedDataIndex]["template_data"] &&
            data["template_data"]
          ) {
            result[existedDataIndex]["template_data"] = {
              ...result[existedDataIndex]["template_data"],
              ...data["template_data"],
            };
          }
        }
      });
      return result;
    },
    //Update parent entity data by entity variable
    async updateParentDataByEntityVariable(fields, form, includeData) {
      let updatedData = [];
      updatedData = [
        ...updatedData,
        ...(await this.getParentUpdatingData(fields, form)),
      ];
      updatedData = this.removeDuplicateData(updatedData);
      let allPromises = await updatedData.map(async (dt) => {
        let params = { ...dt };
        let company_id =
          includeData && includeData.company_id
            ? includeData.company_id
            : this.currentEntity && this.currentEntity.company_id
            ? this.currentEntity.company_id
            : this.formbuilderDetails && this.formbuilderDetails.company
            ? this.formbuilderDetails.company
            : "";
        let userId =
          this.currentEntity?.created_by || this.formbuilderDetails?.created_by;
        if (company_id && userId) {
          params = {
            ...params,
            ...{
              company_id: company_id,
              user_id: userId,
            },
          };
        }
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          params
        );
        return dt;
      });
      await Promise.all(allPromises);
    },
    //check and update entity data
    checkAndAddEntityData(existedData, form, entity_data_id, temp, parent) {
      let updatedData = [];
      if (existedData && existedData.length) {
        updatedData = [...existedData];
      }
      let existedDataIndex = updatedData.findIndex(
        (e) => e.entity_data_id == entity_data_id && temp == e.template_id
      );

      if (existedDataIndex > -1) {
        let data = {
          ...updatedData[existedDataIndex].template_data,
          ...form,
        };
        updatedData[existedDataIndex] = {
          ...updatedData[existedDataIndex],
          ...{ template_data: { ...data } },
        };
      } else {
        updatedData.push({
          template_data: { ...form },
          entity_data_id: entity_data_id,
          template_id: temp,
          isParentUpdate: true,
          entity_id: parent.entity_id,
        });
      }
      return updatedData;
    },
    //check and get updating data
    async getParentUpdatingData(fields, form, existedData) {
      let updatedData = [];
      if (existedData && existedData.length) {
        updatedData = [...updatedData, ...existedData];
      }
      let fieldPromises = await fields.map(async (field) => {
        //take only entity variables & parent update flag
        if (
          field?.input_type == "ENTITY_VARIABLE" &&
          field.relationship_key &&
          form[field.relationship_key] &&
          field.global_variable_entity_field &&
          field.global_variable_entity_field.includes("#") &&
          ((!field.variable_action &&
            field.field_assignable == "update_parent") ||
            field.variable_action == "RESOURCE_AVERAGE")
        ) {
          let [temp, key] = field.global_variable_entity_field.split("#");
          let parent = fields.find(
            (e) => e.inputType == "ENTITY" && e.key == field.relationship_key
          );
          if (parent?.allow_multiple) {
            let values = form[field.key];
            if (field.variable_action == "RESOURCE_AVERAGE") {
              let resourceFilter = (parent.filters || []).find(
                (e) => e.field == "RESOURCE_MANAGEMENT"
              );
              if (
                resourceFilter?.update_resource_data &&
                resourceFilter?.update_resource_data?.resource_count_field &&
                parent?.auto_complete &&
                form[field.key + "?selectedData"] &&
                form[field.key + "?selectedData"].length
              ) {
                let [qTemplate, qKey] =
                  resourceFilter.update_resource_data.resource_count_field.split(
                    "#"
                  );
                form[field.key + "?selectedData"].forEach((entityData) => {
                  let tempForm = {};
                  if (
                    resourceFilter?.update_resource_data
                      ?.resource_state_change &&
                    entityData?.completed
                  ) {
                    (
                      resourceFilter.update_resource_data
                        .resource_state_change || []
                    ).forEach((fl) => {
                      if (
                        fl?.field &&
                        resourceFilter?.update_resource_data
                          ?.resource_state_data
                      ) {
                        let [fTemplate, fKey] = fl.field.split("#");
                        updatedData.push({
                          template_data: {
                            [fKey]:
                              resourceFilter.update_resource_data
                                .resource_state_data[fl.field],
                          },
                          entity_data_id: entityData._id,
                          template_id: fTemplate,
                          isParentUpdate: true,
                          entity_id: parent.entity_id,
                        });
                        // updatedData = [
                        //   ...updatedData,
                        //   ...this.checkAndAddEntityData(
                        //     updatedData,
                        //     {
                        //       [fKey]:
                        //         resourceFilter.update_resource_data
                        //           .resource_state_data[fl.field],
                        //     },
                        //     entityData._id,
                        //     fTemplate,
                        //     parent
                        //   ),
                        // ];
                      }
                    });
                  }
                  if (entityData?.entityData?.[qTemplate]?.[qKey]) {
                    if (
                      resourceFilter.update_resource_data
                        .resource_count_subtract
                    ) {
                      tempForm = {
                        [qKey]:
                          parseInt(
                            entityData?.entityData?.[qTemplate]?.[qKey]
                          ) - parseInt(entityData.quantity),
                      };
                    } else {
                      tempForm = {
                        [qKey]:
                          parseInt(
                            entityData?.entityData?.[qTemplate]?.[qKey]
                          ) + parseInt(entityData.quantity),
                      };
                    }
                  } else {
                    tempForm = {
                      [qKey]: entityData.quantity,
                    };
                  }
                  updatedData.push({
                    template_data: tempForm,
                    entity_data_id: entityData._id,
                    template_id: qTemplate,
                    isParentUpdate: true,
                    entity_id: parent.entity_id,
                  });
                  // updatedData = [
                  //   ...updatedData,
                  //   ...this.checkAndAddEntityData(
                  //     updatedData,
                  //     tempForm,
                  //     entityData._id,
                  //     qTemplate,
                  //     parent
                  //   ),
                  // ];
                });
              }
            } else {
              if (form[field.relationship_key].length && values) {
                form[field.relationship_key].forEach((dt) => {
                  let updateValues = values.filter(
                    (el) => el.parentDataId == dt
                  );
                  let tempForm = {
                    [field.relationship_key]: dt,
                    [field.key]: updateValues,
                  };
                  updatedData = this.checkAndUpdateExistedData(
                    updatedData,
                    parent,
                    tempForm,
                    field,
                    key,
                    temp
                  );
                });
              }
            }
          } else {
            updatedData = this.checkAndUpdateExistedData(
              updatedData,
              parent,
              form,
              field,
              key,
              temp
            );
          }
        }
        //need to check entity variables in data tables also
        if (field?.input_type == "DATA_TABLE") {
          let rowPromises = await (form[field.key] || []).map(async (el) => {
            let data = await this.getParentUpdatingData(
              field.data_table_columns || [],
              el,
              updatedData
            );
            updatedData = [...updatedData, ...data];
          });
          await Promise.all(rowPromises);
        }
        return field;
      });
      await Promise.all(fieldPromises);
      return updatedData;
    },
    async getParentUpdatingData1(fields, form, existedData) {
      let updatedData = [];
      if (existedData && existedData.length) {
        updatedData = [...updatedData, ...existedData];
      }
      let fieldPromises = await fields.map(async (field) => {
        if (
          field.field_type == "ENTITY_VARIABLE" &&
          field.parent_entity_field_key &&
          form[field.parent_entity_field_key] &&
          field.field_assignable &&
          field.global_variable_entity_field &&
          field.global_variable_entity_field.includes("#")
        ) {
          let [temp, key] = field.global_variable_entity_field.split("#");
          let parent = fields.find((e) => e.type == "ENTITY");
          if (parent?.allow_multiple) {
            let values = form[field.key];
            if (form[field.relationship_key].length && values) {
              form[field.relationship_key].forEach((dt) => {
                let updateValues = values.filter((el) => el.parentDataId == dt);
                let tempForm = {
                  [field.relationship_key]: dt,
                  [field.key]: updateValues,
                };
                updatedData = this.checkAndUpdateExistedData1(
                  updatedData,
                  parent,
                  tempForm,
                  field,
                  key,
                  temp
                );
              });
            }
          } else {
            updatedData = this.checkAndUpdateExistedData1(
              updatedData,
              parent,
              form,
              field,
              key,
              temp
            );
          }
        }
        //need to check entity variables in data tables also
        if (field?.input_type == "DATA_TABLE") {
          let rowPromises = await (form[field.key] || []).map(async (el) => {
            updatedData = [
              ...updatedData,
              ...(await this.getParentUpdatingData(
                field.data_table_columns || [],
                el,
                updatedData
              )),
            ];
          });
          await Promise.all(rowPromises);
        }
        return field;
      });
      await Promise.all(fieldPromises);
      return updatedData;
    },
    //check and update existed data
    checkAndUpdateExistedData1(updatedData, parent, form, field, key, temp) {
      let existedDataIndex = updatedData.findIndex(
        (e) =>
          e.entity_data_id == parent.entity_data_id && temp == e.template_id
      );
      let value = form[field.key];
      if (field.inputType == "DATA_TABLE") {
        value = value.filter((e) => !e.newlyAddedData);
        let data = form[field.key] || [];
        value = value.map((e) => {
          let spliteData = data.filter(
            (el) => el.newlyAddedData && el.keyIndex == e.keyIndex
          );
          if (spliteData && spliteData.length) {
            (field.data_table_columns || []).forEach((el) => {
              let sum = parseInt(e[el.key]);
              if (
                el?.field_assignable &&
                (el?.field_operation == "SUM" ||
                  el?.field_operation == "AVERAGE")
              ) {
                spliteData.forEach((dt) => {
                  if (this.isNumber(dt[el.key])) {
                    sum = sum + parseInt(dt[el.key]);
                  }
                });
              }
              if (el?.field_operation == "SUM") {
                e[el.key] = sum;
              } else if (el?.field_operation == "AVERAGE") {
                e[el.key] = sum / (spliteData.length + 1);
              }
            });
          }
          return e;
        });
      }
      if (existedDataIndex > -1 && updatedData[existedDataIndex]) {
        let data = {
          ...updatedData[existedDataIndex].template_data,
          ...{
            [key]: value,
            ...(form[field.key + "/name"] && {
              [key + "/name"]: form[field.key + "/name"],
            }),
            ...(form[field.key + "_code"] && {
              [key + "_code"]: form[field.key + "_code"],
            }),
          },
        };
        updatedData[existedDataIndex] = {
          ...updatedData[existedDataIndex],
          ...{ template_data: { ...data } },
        };
      } else {
        updatedData.push({
          template_data: {
            [key]: value,
            ...(form[field.key + "/name"] && {
              [key + "/name"]: form[field.key + "/name"],
            }),
            ...(form[field.key + "_code"] && {
              [key + "_code"]: form[field.key + "_code"],
            }),
          },

          entity_data_id: parent.entity_data_id,
          template_id: temp,
          isParentUpdate: true,
          entity_id: parent.entity_id,
          table_columns: (field.inputType == "DATA_TABLE" &&
          field.data_table_columns &&
          field.data_table_columns.length
            ? field.data_table_columns.filter(
                (e) => e.field_assignable != "update_parent"
              )
            : []
          ).map((e) => {
            e.parent_table_key = key;
            return e;
          }),
        });
      }
      return updatedData;
    },
    checkAndUpdateExistedData(updatedData, parent, form, field, key, temp) {
      let existedDataIndex = updatedData.findIndex(
        (e) =>
          e.entity_data_id == form[field.relationship_key] &&
          temp == e.template_id
      );
      let value = form[field.key];
      if (field.inputType == "DATA_TABLE") {
        value = value.filter((e) => !e.newlyAddedData);
        let data = form[field.key] || [];
        value = value.map((e) => {
          let spliteData = data.filter(
            (el) => el.newlyAddedData && el.keyIndex == e.keyIndex
          );
          if (spliteData && spliteData.length) {
            (field.data_table_columns || []).forEach((el) => {
              let sum = parseInt(e[el.key]);
              if (
                el?.field_assignable == "update_parent" &&
                (el?.field_operation == "SUM" ||
                  el?.field_operation == "AVERAGE")
              ) {
                spliteData.forEach((dt) => {
                  if (this.isNumber(dt[el.key])) {
                    sum = sum + parseInt(dt[el.key]);
                  }
                });
              }
              if (el?.field_operation == "SUM") {
                e[el.key] = sum;
              } else if (el?.field_operation == "AVERAGE") {
                e[el.key] = sum / (spliteData.length + 1);
              }
            });
          }
          return e;
        });
      }
      if (existedDataIndex > -1 && updatedData[existedDataIndex]) {
        let data = {
          ...updatedData[existedDataIndex].template_data,
          ...{
            [key]: value,
            ...(form[field.key + "/name"] && {
              [key + "/name"]: form[field.key + "/name"],
            }),
            ...(form[field.key + "_code"] && {
              [key + "_code"]: form[field.key + "_code"],
            }),
          },
        };
        updatedData[existedDataIndex] = {
          ...updatedData[existedDataIndex],
          ...{ template_data: { ...data } },
        };
      } else {
        updatedData.push({
          template_data: {
            [key]: value,
            ...(form[field.key + "/name"] && {
              [key + "/name"]: form[field.key + "/name"],
            }),
            ...(form[field.key + "_code"] && {
              [key + "_code"]: form[field.key + "_code"],
            }),
          },
          entity_data_id: form[field.relationship_key],
          template_id: temp,
          isParentUpdate: true,
          entity_id: parent.entity_id,
          table_columns: (field.inputType == "DATA_TABLE" &&
          field.data_table_columns &&
          field.data_table_columns.length
            ? field.data_table_columns.filter(
                (e) => e.field_assignable != "update_parent"
              )
            : []
          ).map((e) => {
            e.parent_table_key = key;
            return e;
          }),
        });
      }
      return updatedData;
    },
  },
};
